import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ba98287"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "fixed"
};
const _hoisted_2 = {
  style: {
    "font-size": "0"
  }
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    class: "img_wh",
    src: $props.content.kefuimg
  }, null, 8, _hoisted_3), _createElementVNode("div", {
    class: "relative",
    style: _normalizeStyle({
      left: $data.clleft + 'px',
      top: $data.cltop + 'px'
    })
  }, [_createElementVNode("img", {
    class: "qr-code",
    style: _normalizeStyle({
      width: $data.imgsize + 'px'
    }),
    src: $data.img
  }, null, 12, _hoisted_4)], 4)])]);
}