import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "spacing",
    style: _normalizeStyle({
      'margin-top': $props.content.topMargin + 'px',
      'margin-left': $props.content.leftMargin + 'px',
      'margin-right': $props.content.rightMargin + 'px',
      'margin-bottom': $props.content.bottomMargin + 'px'
    })
  }, [_createElementVNode("div", {
    class: "flex",
    style: _normalizeStyle({
      'font-size': $props.content.wordSize + 'px',
      color: $props.content.wordColor
    })
  }, _toDisplayString($props.content.name), 5), _createElementVNode("div", {
    class: "fu_title",
    style: _normalizeStyle({
      'font-size': $props.content.subtitleSize + 'px',
      color: $props.content.subtitleColor
    })
  }, _toDisplayString($props.content.subtitle), 5)], 4);
}