import { CircleClose } from '@element-plus/icons-vue';
import * as Vue from 'vue';
import popupPicture from './popupPicture';
import popupRich from './popupRich';
import popupAgreement from './popupAgreement';
import popupAgreetContent from './popupAgreetContent';
import popupButtom from './popupButtom';
//接口
import * as apis from '@/api/land';
export default {
  components: {
    popupPicture,
    popupRich,
    popupAgreement,
    popupAgreetContent,
    popupButtom,
    CircleClose
  },
  props: ['popcontent', 'popsetting', 'tid'],
  data() {
    return {
      bool: false,
      agreetContent: '',
      isxieyi: false,
      iszhezhao: false
    };
  },
  watch: {
    popcontent: {
      handler(value) {},
      deep: true
    },
    popsetting: {
      handler(value) {
        //console.log(this.popsetting)
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.bool = false;
      this.iszhezhao = false;
    },
    popxie(row) {
      this.agreetContent = row;
      this.isxieyi = true;
    },
    closeisxieyi() {
      this.isxieyi = false;
    },
    //跳转微信小程序
    openWechat(landing_page_id, channel_id) {
      if (landing_page_id == '' || channel_id == '' || this.tid == '') return false;
      let parse = {
        tid: this.tid,
        landing_page_id: landing_page_id,
        //落地页id
        channel_id: channel_id //落地页渠道id
      };

      apis.generateScheme(parse).then(res => {
        if (res.code == '200' && res.data.url_scheme) {
          window.location.href = res.data.url_scheme;
        }
      });
    }
  }
};