import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  width: "100%",
  class: "videowh",
  controls: ""
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("video", _hoisted_1, [_createElementVNode("source", {
    src: $props.content.src,
    type: "video/mp4"
  }, null, 8, _hoisted_2), _createElementVNode("source", {
    src: $props.content.src,
    type: "video/ogg"
  }, null, 8, _hoisted_3), _createElementVNode("source", {
    src: $props.content.src,
    type: "video/webm"
  }, null, 8, _hoisted_4), _createElementVNode("object", {
    src: $props.content.src,
    width: "100%"
  }, [_createElementVNode("embed", {
    src: $props.content.src,
    width: "100%"
  }, null, 8, _hoisted_6)], 8, _hoisted_5)])]);
}