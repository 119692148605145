import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a66990e2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "fixed_tip"
};
const _hoisted_2 = {
  class: ""
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_circle_close = _resolveComponent("circle-close");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "box_left_con",
    style: _normalizeStyle({
      'border-radius': $props.content.boxRadius + 'px',
      'background-color': $props.content.bgColor,
      backgroundImage: 'url(' + $props.content.popimg + ')'
    })
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "box_left_con_box_pd_title",
    style: _normalizeStyle({
      'font-size': $props.content.titleSize + 'px',
      color: $props.content.titleColor
    })
  }, _toDisplayString($props.content.title), 5), _createElementVNode("div", {
    class: "box_left_con_box_pd_com",
    innerHTML: $props.content.content
  }, null, 8, _hoisted_3)])], 4), _createElementVNode("div", {
    class: "close",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.close && $options.close(...args))
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_circle_close)]),
    _: 1
  })])]);
}