import { CircleClose } from '@element-plus/icons-vue';
import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer';
import * as Vue from 'vue';
export default {
  components: {
    CircleClose
  },
  props: {
    content: Object
  },
  data() {
    return {};
  },
  methods: {
    close() {
      $emit(this, 'closeisxieyi');
    }
  },
  emits: ['closeisxieyi']
};