import * as Vue from 'vue';
export default {
  props: {
    content: Object
  },
  methods: {
    telPhone() {
      window.location.href = 'tel:' + this.content.telphone;
    }
  }
};