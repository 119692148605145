import { ElAvatar } from 'element-plus';
import { $emit } from '@/utils/gogocodeTransfer';
import { landingPageAnswerDetail } from '@/api/land';
export default {
  name: 'Logic',
  components: {
    ElAvatar
  },
  props: {
    content: Object
  },
  data() {
    return {
      questionsAndAnswers: [],
      //聊天数据
      toBeAnswers: [],
      //待选项
      step: 0,
      loading: false,
      answerHeight: 100,
      init: false,
      shake: false
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.landingPageAnswerDetail();
      this.handleChatingData();
    },
    async landingPageAnswerDetail() {
      const {
        data
      } = await landingPageAnswerDetail({
        id: this.content.logic.id
      });
      this.content.logic = data;
      this.init = true;
    },
    handleChatingData(item) {
      if (this.step <= this.content.logic.content.questions_answers.length) {
        if (this.step !== 0) {
          this.questionsAndAnswers.push(item);
        }
        if (this.step < this.content.logic.content.questions_answers.length) {
          this.questionsAndAnswers.push(...this.content.logic.content.questions_answers[this.step].questions);
          this.toBeAnswers = this.content.logic.content.questions_answers[this.step].answers;
        } else {
          this.toBeAnswers = [];
        }
      }
    },
    handleClickAnswer(item) {
      if (this.shake) return;
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 1050);
      this.step++;
      if (item.is_click === 1) {
        //有点击动作
        this.questionsAndAnswers.push(item);
        switch (item.click_type) {
          case 1:
            if (item.click_jump_type === 3) {
              // 网页跳转
              $emit(this, 'openUrlLink', item.click_jump_URL);
              return;
            } else if (item.click_jump_type === 2) {
              // 获客助手
              // $emit(this,'jumpCustomerAcquisition',item)
              console.log(item);
              $emit(this, 'goHuoke', item.customerAcquisition);
              return;
            }
            $emit(this, 'openWechat', item.wholeApplet, item.wholeAppletType);
            break;
          case 2:
            this.toBeAnswers = [];
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.questionsAndAnswers.push({
                question_content: item.final_content
              });
              this.toEnd();
            }, 1000);
            break;
          case 3:
            const jump = this.content.logic.content.questions_answers.filter(val => {
              return val.id === item.jump_question_answer;
            });
            if (jump.length) {
              this.loading = true;
              setTimeout(() => {
                this.loading = false;
                this.questionsAndAnswers.push(...jump[0].questions);
                this.toBeAnswers = jump[0].answers;
                this.toEnd();
              }, 1000);
            }
            break;
        }
      } else if (item.is_click === 2) {
        //无点击动作
        // 无反应
      }
      this.toEnd();
    },
    toEnd() {
      //滚动到底部
      this.$nextTick(() => {
        setTimeout(() => {
          this.answerHeight = this.$refs.answer.clientHeight + 50;
          let middle = this.$refs['questionContainer'];
          this.$nextTick(() => {
            middle.scrollTop = middle.scrollHeight;
          });
        }, 0);
      });
    }
  }
};