import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer';
import * as Vue from 'vue';
// 接口
import * as apis from '@/api/land';
export default {
  props: {
    content: Object,
    pageType: Boolean,
    tid: String,
    landingPageId: Number,
    modelType: {
      type: String,
      default: 'android'
    }
  },
  data() {
    return {
      img: '',
      fontSize: '',
      screenWidth: document.body.clientWidth,
      cliw: '',
      clleft: '',
      cltop: '',
      imgsize: ''
    };
  },
  computed: {
    needBase64() {
      return this.modelType === 'ios' && this.pageType;
    }
  },
  mounted() {
    let clientWidth = document.documentElement.clientWidth;
    if (clientWidth > 800) clientWidth = 800;
    const wsize = (clientWidth / 372).toFixed(2);
    this.imgsize = wsize * this.content.w;
    this.clleft = wsize * this.content.x;
    this.cltop = wsize * this.content.y;
    if (this.pageType) {
      // 小程序
      this.wechat_qrcode_trace();
    } else {
      // h5
      this.wechatqrcode();
      this.trace();
    }
  },
  methods: {
    trace() {
      const parse = {
        tid: this.tid,
        step: "31"
      };
      apis.trace(parse).then(res => {});
    },
    wechatqrcode() {
      const parse = {
        wechat_package_id: this.content.wechat
      };
      apis.wechatqrcode(parse).then(res => {
        if (res.code == '200') {
          this.img = res.data.qr_code;
          var obj = {
            id: res.data.id,
            target_id: res.data.target_id
          };
          $emit(this, 'userwechatid', obj);
        } else {
          $emit(this, 'userwechatid');
        }
      });
    },
    wechat_qrcode_trace() {
      let parse;
      if (this.needBase64) {
        parse = {
          tid: this.tid,
          wechat_package_id: this.content.wechat,
          landing_page_id: this.landingPageId,
          need_base64: 1
        };
      } else {
        parse = {
          tid: this.tid,
          wechat_package_id: this.content.wechat,
          landing_page_id: this.landingPageId,
          need_base64: 2
        };
      }
      apis.wechat_qrcode_trace(parse).then(res => {
        if (this.needBase64) {
          this.img = res.data.base64;
        } else {
          this.img = res.data.qr_code;
        }
        $emit(this, 'handleCanvas');
      });
    }
  },
  emits: ['userwechatid', 'handleCanvas']
};