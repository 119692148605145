import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-86b26d72"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "jumpIndex"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
import { ElCarousel, ElCarouselItem } from 'element-plus';
import { ref } from 'vue';
export default {
  setup(__props) {
    const imgUrl = ref('https://image.saas.soofel.com/image/common/index/');
    const carouselImages = ref(['banner1.png', 'banner2.png', 'banner3.png']);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        class: "my-header",
        src: imgUrl.value + 'header.png'
      }, null, 8, _hoisted_2), _createVNode(_unref(ElCarousel), {
        class: "carousel"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(carouselImages.value, item => {
          return _openBlock(), _createBlock(_unref(ElCarouselItem), {
            key: item
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: 'https://image.saas.soofel.com/image/common/index/' + item
            }, null, 8, _hoisted_3)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }), _createElementVNode("img", {
        class: "my-header",
        src: imgUrl.value + 'contain.png'
      }, null, 8, _hoisted_4), _createElementVNode("img", {
        class: "my-header",
        src: imgUrl.value + 'item.jpg'
      }, null, 8, _hoisted_5), _createElementVNode("img", {
        class: "my-header",
        src: imgUrl.value + 'details.png'
      }, null, 8, _hoisted_6)]);
    };
  }
};