import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer';
import * as Vue from 'vue';
export default {
  props: ['content', 'hrefs'],
  data() {
    return {
      width: '',
      height: '',
      clleft: '',
      cltop: ''
    };
  },
  mounted() {
    let clientWidth = document.documentElement.clientWidth;
    let clientHeight = document.documentElement.clientHeight;
    if (clientWidth > 800) clientWidth = 800;
    const wsize = (clientWidth / 375).toFixed(2);
    this.width = wsize * this.content.w;
    this.height = wsize * this.content.h;
    this.clleft = wsize * this.content.x;
    if (this.content.isBottom) {
      this.cltop = `calc(100% - ${this.height}px)`;
    } else {
      this.cltop = this.content.y + 'px';
    }
  },
  methods: {
    openpage(row) {
      //linkType 0 整图调整  1单图调整
      if (this.content.isclick) {
        if (this.content.linkType === 0) {
          if (this.content.wholeType === 2) {
            $emit(this, 'openWechat', this.content.appletlink, this.content.appletUrl, this.content.wholeApplet, this.content.wholeAppletType);

            // if (this.content.appletlink == 0) {
            //   $emit(
            //     this,
            //     'openWechat',
            //     this.content.wholeApplet,
            //     this.content.wholeAppletType
            //   )
            // } else {
            //   window.location.href = this.content.appletUrl
            // }
          } else if (this.content.wholeType === 0) {
            let id = 'new_a';
            let a = document.createElement('a');
            a.setAttribute('href', this.content.webpageUrl);
            a.setAttribute('target', '_blank');
            a.setAttribute('id', id);
            // 防止反复添加
            if (!document.getElementById(id)) {
              document.body.appendChild(a);
            }
            a.click();
          }
        } else if (this.content.linkType === 2) {
          window.location.href = `tel:${this.content.telephone}`;
        }
      }
    }
  },
  emits: ['openWechat']
};