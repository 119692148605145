import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23e88e77"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "richtext"
};
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    style: _normalizeStyle({
      'padding-top': $props.content.topMargin + 'px',
      'margin-bottom': $props.content.bottomMargin + 'px',
      'margin-left': $props.content.leftMargin + 'px',
      'margin-right': $props.content.rightMargin + 'px'
    })
  }, [_createElementVNode("div", {
    class: "spacing ql-editor",
    innerHTML: $props.content.myValue,
    style: _normalizeStyle({
      'border-color': $props.content.borderColor,
      'border-width': $props.content.borderSize + 'px',
      'border-radius': $props.content.borderround + 'px'
    })
  }, null, 12, _hoisted_2)], 4)]);
}