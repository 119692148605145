import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer';
import * as Vue from 'vue';
export default {
  props: {
    content: Object
  },
  mounted() {},
  methods: {
    openCont(item) {
      $emit(this, 'popxie', item);
    }
  },
  emits: ['popxie']
};