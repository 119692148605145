import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-eac488a8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "box_flex"
};
const _hoisted_2 = {
  class: "box_flex_agrent"
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  return _openBlock(), _createElementBlock("div", {
    class: "agreet",
    style: _normalizeStyle({
      'margin-top': $props.content.topMargin + 'px',
      'margin-bottom': $props.content.bottomMargin + 'px',
      'margin-left': $props.content.leftMargin + 'px',
      'margin-right': $props.content.rightMargin + 'px',
      'background-color': $props.content.bgColor,
      backgroundImage: 'url(' + $props.content.bgImg + ')'
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_checkbox, {
    modelValue: $props.content.isagree,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $props.content.isagree = $event)
  }, null, 8, ["modelValue"]), _createElementVNode("div", {
    class: "box_flex_agrent_title",
    style: _normalizeStyle({
      color: $props.content.prefixColor
    })
  }, _toDisplayString($props.content.treaty), 5), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.content.treatys, (item, index) => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "box_flex_name",
      onClick: $event => $options.openCont(item),
      style: _normalizeStyle({
        color: $props.content.nameColor
      }),
      key: index
    }, " 《" + _toDisplayString(item.title) + "》 ", 13, _hoisted_3)), [[_vShow, $props.content.treatys.length != '0']]);
  }), 128))])])], 4);
}