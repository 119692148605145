export default [

    {
        path: '/',
        name: '落地页',
        redirect: '/home',
        component: () => import('view/index'),
        children: [
            {
                //落地页
                path: '/home',
                name: '落地页',
                component: () => import('view/landPage/index'),
                redirect: '/landFront',
                children: [
                    {
                        //落地页
                        path: '/landFront',
                        name: '落地页',
                        component: () => import('view/landPage/landFront'),
                        meta: {
                            title: '贝颜',
                        },
                    },
                    {
                        //落地页
                        path: '/support',
                        name: '技术支持',
                        component: () => import('view/landPage/support'),
                        meta: {
                            title: '技术支持',
                        },
                    },
                    {
                        //落地页
                        path: '/jumpIndex',
                        name: '技术支持',
                        component: () => import('view/landPage/jumpIndex'),
                        meta: {
                            title: '技术支持',
                        },
                    },
                ],
            },
        ],
    }

]
