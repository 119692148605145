import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9310046c"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "picture",
    style: _normalizeStyle({
      'margin-top': $props.content.topMargin + 'px',
      'margin-left': $props.content.leftMargin + 'px',
      'margin-right': $props.content.rightMargin + 'px',
      'margin-bottom': $props.content.bottomMargin + 'px'
    })
  }, [_createElementVNode("div", {
    class: "list",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.open && $options.open(...args))
  }, [_createElementVNode("img", {
    class: "img",
    src: $props.content.cover,
    alt: ""
  }, null, 8, _hoisted_1)])], 4);
}