export default {
  props: {
    content: Object
  },
  data() {
    return {
      radio: '',
      checkout: [],
      fileValue: '',
      select: ''
    };
  },
  watch: {
    select() {
      this.content.select = this.select;
    }
  },
  created() {
    this.content.radio = '';
    this.content.checkout = [];
    this.content.fileValue = '';
    this.content.select = '';
  },
  methods: {
    change(val) {
      this.content.radio = this.radio;
    },
    checkchange() {
      this.content.checkout = this.checkout;
    },
    changeFile() {
      this.content.fileValue = this.fileValue;
    }
  }
};