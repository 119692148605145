import * as Vue from 'vue';
import { ImagePreview } from 'vant';
export default {
  props: {
    content: Object
  },
  data() {
    return {
      srcList: []
    };
  },
  mounted() {
    if (this.content.imageList.length) {
      this.content.imageList.forEach(item => {
        this.srcList.push(item.img);
      });
    }
  },
  methods: {
    open() {
      ImagePreview({
        images: this.srcList,
        closeable: true
      });
    }
  }
};