import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b45c5e8"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "w_all",
    style: _normalizeStyle({
      'margin-top': $props.content.topMargin + 'px',
      'margin-left': $props.content.leftMargin + 'px',
      'margin-right': $props.content.rightMargin + 'px',
      'margin-bottom': $props.content.bottomMargin + 'px'
    })
  }, [_createElementVNode("div", {
    class: "spacing ql-editor",
    innerHTML: $props.content.myValue
  }, null, 8, _hoisted_1)], 4);
}